var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marquee-table",on:{"mouseleave":_vm.marquee,"mousenter":_vm.stopMarquee}},[_c('div',{staticClass:"header"},_vm._l((_vm.columns),function(cell,index){return _c('span',{key:index,staticClass:"cell",style:({
        width: cell.width ? ((cell.width) + "px") : 'auto',
        flex: cell.width ? 'unset' : 1,
      })},[_vm._v(" "+_vm._s(cell.title)+" ")])}),0),_c('div',{ref:"body",staticClass:"body"},[_c('div',{ref:"scrollWrap",staticClass:"scroll-wrap"},[_c('div',{ref:"scroll",staticClass:"inner"},_vm._l((_vm.data),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"row"},_vm._l((_vm.columns),function(cell,cellIndex){return _c('span',{key:cellIndex,staticClass:"cell",style:({
              width: cell.width ? ((cell.width) + "px") : 'auto',
              flex: cell.width ? 'unset' : 1,
            })},[_vm._v(" "+_vm._s(row[cell.key])+" ")])}),0)}),0),_c('div',{staticClass:"inner"},_vm._l((_vm.data),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"row"},_vm._l((_vm.columns),function(cell,cellIndex){return _c('span',{key:cellIndex,staticClass:"cell",style:({
              width: cell.width ? ((cell.width) + "px") : 'auto',
              flex: cell.width ? 'unset' : 1,
            })},[_vm._v(" "+_vm._s(row[cell.key])+" ")])}),0)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }